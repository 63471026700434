html, body {
	height: 100%;
}

img {
	max-width: 100%;
}

#main {
	min-height: 50%;
	padding-top: 80px;
	padding-bottom: @grid-gutter-width;
}

.oswald {
	font-family: @oswald !important;
}

.open-sans {
	font-family: @open-sans !important;
}

.font-12 {
	font-size: 12px;
	line-height: @line-height-base;
}

.font-13 {
	font-size: 13px;
	line-height: @line-height-base;
}

.font-14 {
	font-size: 14px;
	line-height: @line-height-base;
}

.font-15 {
	font-size: 15px;
	line-height: @line-height-base;
}

.font-16 {
	font-size: 16px;
	line-height: @line-height-base;
}

.font-18 {
	font-size: 18px;
	line-height: @line-height-base;
}

.font-20 {
	font-size: 20px;
	line-height: @line-height-base;
}

.kek {
	color: @kek !important;
}

@media (min-width: @screen-sm-min) {
	.pull-sm-left {
		float: left;
	}

	.pull-sm-right {
		float: right;
	}

	.pull-sm-none {
		float: none;
	}
}

@media (min-width: @screen-md-min) {
	.pull-md-left {
		float: left;
	}

	.pull-md-right {
		float: right;
	}

	.pull-md-none {
		float: none;
	}
}

@media (min-width: @screen-lg-min) {
	.pull-lg-left {
		float: left;
	}

	.pull-lg-right {
		float: right;
	}

	.pull-lg-none {
		float: none;
	}
}

section, article {
	header {
		h1 {
			color: @kek;
			text-transform: uppercase;
			font-size: 24px;
			line-height: @line-height-base;
			margin-top: 0;
		}
	}
}

article {
	ul, ol {
		li {
			color: @kek;
			font-family: @oswald;
			font-size: 16px;
			line-height: 1.6em;
		}
	}

	img {
		margin: @grid-gutter-width / 2;
		border: 5px solid @gray-lighter;
	}
}

#kategoria-lista,
#user-lista {
	> ul > li {
		margin: @grid-gutter-width / 4 0;

		> a {
			.font-15();
			.text-uppercase();
			font-family: oswald;
		}

		> ul > li li {
			margin-left: @grid-gutter-width / 2;
		}
	}

	a {
		.invert();
		color: #111;
	}

	li.aktiv > a {
		color: @kek;
		font-weight: bold;
	}

	> ul > li.aktiv > a {
		font-weight: normal;
	}

}

#user-lista {
	margin: 0 0 50px;
}

#termek-szuro {
	h2 {
		margin-bottom: @grid-gutter-width / 4;
	}
}

.embed-container {
	position: relative;
	padding-bottom: 50%;
	padding-top: 0;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	height: auto;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

#kapcsolat-gmaps {
	height: 300px;
	border: 5px solid @gray-lighter;
}

.gm-style img { // Bootstrap fix, img max-width 100%-ra van állítva enélkül el vannak torzítva
	max-width: none !important;
}

.form-inline {
	.form-group {
		width: 100%;
		margin-bottom: @line-height-computed;
	}

	#termek-szuro & {
		.form-group {
			margin-bottom: 5px;
		}
	}

	input[type=text],
	input[type=email],
	input[type=password],
	select,
	textarea {
		width: 100%;
		border: 2px solid @gray-lighter;
	}

	textarea {
		height: 196px;
	}
}

.partnerek {


	&>li {
		border: 5px solid @kek;
		padding: 20px;
		margin: 0 0 20px;
		position: relative;

		.btn-csoport {
			position: absolute;
			top: 10px;
			right: 10px;
		}
	}
}
