body > header {
	background-color: @white;
}

.legfelso.nav {
	li > a {
		font-size: @font-size-small;
		padding: 5px;
	}
	.lang > a {
		&:not(:first-child){
			padding-left: 0;

			&:before {
				content: "/";
				margin: 0 3px;
				display: inline-block;
			}
		}

		&:not(:last-child){
			padding-right: 0;
		}
	}
	.penznem > .dropdown-menu {
		min-width: 0;

		li a {
			padding-right: 15px;
			padding-left: 10px;
		}
	}
}

.logo {
	@media (max-width: @screen-xs-max){
		margin-left: @grid-gutter-width / 2;
	}
}

.fomenu {
	.navbar-header {
		margin-right: @grid-gutter-width / 2;
	}

	.navbar-nav {
		font-family: @oswald;
	}

	.nav li > a > h3 {
    	font-size: 14px;
    	margin: 0;
	}
}

.kosar-dropdown {
	margin-top: 7px;

	&.dropdown>a {
		line-height: 26px;
		padding-top: 5px;
		padding-bottom: 8px;
	}

	a:hover {
		color: @kek !important;
	}

	.ikon {
		display: inline-block;
		vertical-align: text-bottom;
		width: 28px;
		height: 26px;
		margin: 0 4px;
		background: @kosar-kek 50% 50% no-repeat;
	}
}

.col-kereses {
	margin: 0 auto;
	float: none;
}

.kereses-form {
	@kereses-height: 50px;
	@kereses-border-width: 5px;

	margin-bottom: @kereses-height / -2 - @kereses-border-width;

	.input-group {
		border: @kereses-border-width solid @kek;
	}

	.form-control {
		border: 0 none;
		border-color: transparent;
		.box-shadow(none);
		height: @kereses-height;
		font-size: @font-size-large;
	}

	input:focus {
		border-color: transparent;
		outline: 0 none;
		.box-shadow(none);
	}

	input[type=submit] {
		border: 0 none;
		border-radius: 0;
		width: @kereses-height;
		height: @kereses-height;
		background: @white @kereses 50% 50% no-repeat;
		text-indent: -1000px;
	}
}