body > footer {
	background: @kek;
	border-top: 5px solid @piros;
	padding-top: @grid-gutter-width / 2;
	padding-bottom: @grid-gutter-width;
	color: @white;
	font-size: @font-size-small;

	h3 {
		font-size: @font-size-large;
		margin-bottom: @grid-gutter-width / 2;
	}

	address {
		display: inline;
	}

	a {
		color: @white;

		&:hover {
			color: lighten(@kek, 35%);
		}
	}
}