//
// Breadcrumbs
// --------------------------------------------------


.breadcrumb {
  padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  margin-bottom: @line-height-computed;
  list-style: none;
  background-color: @breadcrumb-bg;
  border-radius: @border-radius-base;
  font-family: @oswald;
  text-transform: uppercase;
  font-size: @font-size-small;
  line-height: @line-height-base;

  > li {
    display: inline-block;

    + li:before {
      content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: @breadcrumb-color;
    }

	a {
		color: @breadcrumb-color;

		&.active, &:hover {
			color: @breadcrumb-active-color;
		}
	}
  }

  > .active {
    // color: @breadcrumb-active-color;
  }
}
