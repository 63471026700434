.termek.media {
	background-color: @gray-lighter;
	padding: 5px;

	&:hover {
		border: 5px solid @kek;
		margin: -5px;

		input[type=submit]{
			background-image: @kosar-kek-kicsi;
		}

		.btn-danger {
			background-color: @kek;
			border-color: @kek;

			&:hover {
				background-color: darken(@kek, 10%);
				border-color: darken(@kek, 10%);
			}
		}
	}

	&+&:hover {
		margin-top: @grid-gutter-width / 2 - 5px;
	}

	.media-heading {
		font-size: 18px;
		line-height: @line-height-base;
	}

	.media-body {
		font-size: 12px;
		line-height: @line-height-base;
	}

	.leiras {
		margin-bottom: 0;
	}

	.osszeg {
		font-size: 18px;
		color: @kek;
		font-weight: bold;
	}

	@media (max-width: 420px){
		>a.pull-left {
			float: none !important;
			display: none;
			margin-bottom: 5px;
		}
	}
}

.kosarba-form {
	input[name=db]{
		width: 40px;
		font-size: 12px;
		height: 26px;
		text-align: right;
		padding: 6px;

		+ label {
			line-height: 26px;
			padding: 0 5px;
		}
	}

	input[name=kosarba_ok]{
		background: transparent @kosar-piros-kicsi 50% 50% no-repeat;
		width: 24px;
		height: 26px;
		text-indent: -1000px;
		border: 0 none;
	}
}

.termek.media.rovid {
	.media-heading {
		font-size: 14px;
	}
}

.termek-adatlap {
	.termekfoto {
		@termekfoto-gutter-width: 20px;

		padding: 0 @termekfoto-gutter-width / 2;
		margin-bottom: @termekfoto-gutter-width;

		img {
			border: 5px solid @gray-lighter;

			&:hover {
				border-color: @kek;
			}
		}
	}

	input[name=db]{
		background: @gray-lighter;
	}
}