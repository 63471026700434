body.fooldal #main {
	padding-top: 0;
}

body.fooldal .uzenetek {
	padding-top: 40px;
}

#fooldal-slider {
	background: @bg-slider;
	border-bottom: 5px solid @kek;
	padding-top: 80px;
	padding-bottom: 50px;
	height: 400px;

	.carousel-caption .btn {
		position: absolute;
		bottom: 0;
		right: 0;
	}

	@media (max-width: @screen-xs-max){
		height: auto;

		.carousel-caption {
			position: static;
			padding-bottom: 0;

			h1 {
				font-size: 24px;
				line-height: @line-height-base;
			}

			.btn {
				position: static;
			}
		}

		.carousel-inner > .item > img {
			margin: 0 auto;
			max-width: 74vw;
		}

		.carousel-control {
			background-size: 60%;
			width: 10vw;
		}
	}
}