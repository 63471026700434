body.kosar {
	.termek-torol {
		width: 20px;
		height: 20px;
		background: transparent @torol-kek 50% 50% no-repeat;
		border: 0 none;
		text-indent: -1000px;
		overflow: hidden;

		&:hover {
			background-image: @torol-piros;
		}

		&:focus {
			outline: none;
		}
	}

	input[type=number]{
		background: transparent;
		border: 0 none;
	}

	textarea {
		height: 142px;
	}
}